<template>
  <div class="VideoChatExitPage">
    <GradientBackLayout
      class="Activity"
      :title="$t('activity_type_wat_videochat')"
      :shadowText="$t('activity')">
      <BoxShadow class="VideoChatExitPage--Box">
        <div class="VideoChatExitPage--Form" v-show="reason != null && reason.length > 0">
          <p>{{$t('videochat_out_message')}}</p>
          <p>{{reason}}</p>
        </div>
        <div class="VideoChatExitPage--Form" v-show="reason == null || reason.length == 0">
          <p>{{$t('videochat_out_message_2')}}</p>
        </div>
      </BoxShadow>
    </GradientBackLayout>
  </div>
</template>

<script>
  import { mapGetters, mapMutations  } from 'vuex';
  import GradientBackLayout from '@/layouts/GradientBackLayout.vue'
  import BoxShadow from '@/components/BoxShadow.vue'
  import store from '@/store';
  export default {
    name: 'VideoChatExitPage',
    props:{
      reason:{
        type:String,
        default:null
      }
    },
    components: {
      GradientBackLayout,
      BoxShadow
    },
  };
</script>
<style lang="scss">
.VideoChatExitPage{
  .GradientBack{
    height: 100vh;
    margin-bottom: 30px;
  }
}
</style>
<style scoped lang="scss">
.VideoChatExitPage{
  &--Box{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
  }
  &--Form{
    border: 1px solid lightgray;
    padding: 25px;
    display: flex;
    flex-direction: column;
    p{
      font-size: 19px;
    }
  }
}
</style>
